import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Aside from "../components/Aside/Aside";
import Footer from "../components/Footer";
import { toast } from 'react-toastify';
import { useLazyQuery, useMutation } from "@apollo/client";
import { ALLCATEGORYLIST } from "../framework/graphql/querys/Category";
import { useFormik } from "formik";
import { SubCategoryValidationSchema } from "../utils/YupValidations";
import { CREATESUBCATEGORY } from "../framework/graphql/mutations/SubCategory";
import { ALLSUBCATEGORYLIST } from "../framework/graphql/querys/SubCategory";
import Loader from "../components/Loading/Loader";

const SubCategory =()=>{
    const[allSubCategory,setAllSubCategory] = useState<Array<any>>([]);
    const [allCategory,setAllCategory] = useState<Array<any>>([]);
    const [addSubCategory, { loading:addSubCategoryLoading }] = useMutation(CREATESUBCATEGORY);
    const [GetAllCategory, { loading:categoryLoading, data:categoryData }] = useLazyQuery(ALLCATEGORYLIST,{
        variables:{
          offset: 1,
          limit: 100,
          query: "",
          sortColumn: "id",
          sortOrder: "ASC"
        }
      });
      const [GetAllSubCategory, { loading:subCategoryLoading, data:subCategoryData }] = useLazyQuery(ALLSUBCATEGORYLIST,{
        variables:{
          offset: 1,
          limit: 100,
          query: "",
          sortColumn: "id",
          sortOrder: "ASC"
        }
      });
      useEffect(()=>{
        GetAllCategory().then((res)=>{
          setAllCategory(res?.data?.listCategory?.data);
        });
    },[categoryData]);

    useEffect(()=>{
        GetAllSubCategory().then((res)=>{
            setAllSubCategory(res?.data?.listSubCategory?.data);
        });
    },[subCategoryData]);
    const initialValues = {
        categoryUuid: "",
        subcategoryname:"",
        slug: "",
        status: true
    };
    const formik = useFormik({
        initialValues,
        validationSchema: SubCategoryValidationSchema,
        onSubmit: (values) => {
            if(values){
                const statusValue = values.status === true ? 'ACTIVE': 'INACTIVE';
                const formData = {};
                Object.assign(formData,{
                    categoryUuid: values.categoryUuid ,
                    subCatName: values.subcategoryname,
                    subCatSlug: values.slug,
                    subCatStatus: statusValue 
                });
                addSubCategory({
                variables: formData
                }).then((res)=>{
                const data = res.data;
                toast.success(data?.createSubCategory?.meta?.message);
                formik.resetForm();
                },(err)=>{
                const data = err.data;
                toast.success(data?.createSubCategory?.meta?.message);
                })
            }
        },
    });
    const handleChange = (event: any) => {
        const { value } = event.target;
        const modifiedValue = value.replace(/\s/g, "-").toLowerCase();
        formik.setFieldValue("subcategoryname", value);
        formik.handleChange({
          target: {
            name: "slug",
            value: modifiedValue,
          },
        });
      };

    const handleStatusChange =()=>{
        formik.setFieldValue("status", !formik.values.status);
    }
    if(subCategoryLoading ||  categoryLoading){
      return (
          <Loader />
      )
    }
    return(
        <>
      <Header />
      <Aside />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Sub Category</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Home</a>
              </li>
              <li className="breadcrumb-item active">Sub Category</li>
            </ol>
          </nav>
          <div className="addButton">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="bi bi-plus"></i> Add New Sub Category
            </button>
          </div>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Sub Category List</h5>
                {allSubCategory && allSubCategory.length > 0 && (
                  <table className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Sr No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Slug</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allSubCategory &&
                        allSubCategory.length &&
                        allSubCategory.map((row) => (
                          <tr key={row?.id}>
                            <td>{row?.id}</td>
                            <td>{row?.name}</td>
                            <td>{row?.slug}</td>
                            <td colSpan={3}>
                              <a href="#" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bi bi-pen"></i></a> | <a href="#" className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bi bi-trash-fill"></i></a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Sub Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form className="row g-2" onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <div className="col-md-12 mb-2">
                    <select className="form-select" 
                        name="categoryUuid"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.categoryUuid}
                    >
                        <option value="">Select an option</option>
                        {allCategory &&
                        allCategory.length &&
                        allCategory.map((row) => 
                            <option key={row?.uuid} value={row?.uuid}>{row?.name}</option>
                        )}
                    </select>
                    {formik.errors.categoryUuid &&
                    formik.touched.categoryUuid && (
                      <div className="formik-error">
                        {formik.errors.categoryUuid}
                      </div>
                    )}
                </div>
                <div className="col-md-12 mb-2">
                  <label>Sub Category Name<i className="required">*</i></label>
                  <input
                    type="text"
                    name="subcategoryname"
                    className="form-control"
                    placeholder="Enter Sub Category Name"
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subcategoryname}
                  />
                  {formik.errors.subcategoryname &&
                    formik.touched.subcategoryname && (
                      <div className="formik-error">
                        {formik.errors.subcategoryname}
                      </div>
                    )}
                </div>
                <div className="col-md-12 mb-2">
                <label> Slug<i className="required">*</i></label>
                  <input
                    type="text"
                    name="slug"
                    className="form-control"
                    placeholder="Slug *"
                    value={formik.values.slug}
                    disabled
                  />
                  {formik.errors.slug && formik.touched.slug && (
                    <div className="formik-error">{formik.errors.slug}</div>
                  )}
                </div>
                <div className="col-md-12 mb-2">
                    <label>Status<i className="required">*</i></label>
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="status" onChange={handleStatusChange} checked={formik.values.status}/>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
    );
}
export default SubCategory;

