import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../fragments/metaFragments";

export const CREATECATEGORY = gql`
mutation Fileupload(
    $image:Upload!
    $categoryName: String!
    $categorySlug: String!
    $categoryStatus: CategoryStatusEnum!
    ){ 
    createCategory(input:{
    category_image:$image, 
    category_name: $categoryName, 
    category_slug: $categorySlug, 
    category_status: $categoryStatus
  }){
    meta{
        ...MetaFragment
     }
  }
}
${META_FRAGMENT}
`;

export const DELETECATEGORY = gql`
mutation deleteCategory($uuid: String!) {
  deleteCategory(input: { uuid: $uuid }) {
    meta{
      ...MetaFragment
    }
  }
}
${META_FRAGMENT}
`;