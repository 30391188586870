import { KEYS } from "../costant/constant";

// export const getCurrentAdmin = () => {
//   const adminInfo = localStorage.getItem(KEYS.adminInfo);
//   return adminInfo ? JSON.parse(adminInfo) : null;
// };

export const getAdminAuthToken = () => {
  const token = localStorage.getItem(KEYS.authAdminToken);
  return token ? token : null;
}

export const checkAuthentication = (): boolean => {
    // Assuming you have a token or some form of user authentication state
    // Check if the user is authenticated. Here, a boolean flag is used.
  const userToken = getAdminAuthToken(); // Example: Check if a token exists in localStorage
  
  return !!userToken; // Return true if the user is authenticated, false otherwise
};

export const adminLogout = () =>{
  localStorage.removeItem(KEYS.adminInfo);
  localStorage.removeItem(KEYS.authAdminToken);
  window.location.replace('/');
}