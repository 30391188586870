import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { adminLogout, getAdminAuthToken } from '../../helper';
import { toast } from 'react-toastify';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
const httpLink = new HttpLink({uri:process.env.REACT_APP_GRAPHQL_ENDPOINT});

const authMiddleware = new ApolloLink((operation,forward)=>{
  //Get admin Auth token
  let token;
  token = getAdminAuthToken();

  //Set the custom header
  const customHeaders = {
    'Authorization': `Bearer ${token}` || null,
    'Content-Type': 'application/json'
  }

  operation.setContext(({headers = {} })=>({
    headers:{
      ...customHeaders,
      ...headers,
    },
  }));

  return forward(operation);
});

const getUnAuthenticatedError = ()=>{
  if(getAdminAuthToken()){
    adminLogout();
    window.location.replace('/admin/login');
  }
}

const errorLink = onError(({ graphQLErrors})=>{
  if(graphQLErrors){
    graphQLErrors.forEach(({message,path,extensions})=>{
      if(path === 'adminLogout'){
        adminLogout();
      }else if(message === 'unauthenticated.'){
        getUnAuthenticatedError();
      }else if (extensions.category === 'validation'){
        for(const key in extensions?.validation){
          key &&
            toast.error(extensions?.validation[key][0]);
        }
      }else if(extensions?.meta?.message_code === 'UNAUTHORIZED_ACCESS'){
        toast.error(extensions?.meta?.message,{
          onClose:()=> window.location.replace('admin/login')
        })
      }else{
        toast.error(message);
      }
    });
  }
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, httpLink])
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT
});

export const uploadFileClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authMiddleware, errorLink, uploadLink]),
});