import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../fragments/metaFragments";

export const ALLSUBCATEGORYLIST = gql`
query listSubCategory (
    $offset: Int!
    $limit: Int!
    $query: String
    $sortColumn: String
    $sortOrder: String
    ){
    listSubCategory(
      input: {
        offset: $offset
        limit: $limit
        query: $query
        sort_column: $sortColumn
        sort_order: $sortOrder
      }
    ) {
        meta {
            ...MetaFragment
        }
        data {
            id
            category_name
            uuid
            name
            slug
            status
        }
        pagination {
            total_count
            offset
            limit
        }
    }
  }
${META_FRAGMENT}
`;