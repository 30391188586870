import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../fragments/metaFragments";

export const ALLPRODUCTS = gql`
query listProduct (
    $offset: Int!
    $limit: Int!
    $query: String
    $sortColumn: String
    $sortOrder: String
    ){
    listProduct(
      input: {
        offset: $offset
        limit: $limit
        query: $query
        sort_column: $sortColumn
        sort_order: $sortOrder
      }
    ) {
        meta {
            ...MetaFragment
        }
        data {
            uuid
            category_name
            sub_category_name
            name
            part_number
            outer_td
            outer_bd
            inner_td
            inner_bd
            no_check_valve
            no_pass_valves
            thread
            height
            main_img
            sec_img
            thrd_img
            price
            status
          }
        pagination {
            total_count
            offset
            limit
        }
    }
  }
${META_FRAGMENT}
`;