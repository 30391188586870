import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../fragments/metaFragments";

 export const ADMINLOGIN = gql`
    mutation adminLogin($email: String!, $password: String!){
        adminLogin(input:{email: $email, password: $password}){
            meta{
                ...MetaFragment
            }
            data{
                token_type
                expires_in
                access_token
                user {
                    first_name
                    last_name
                    email
                }
            }
        }
    }
${META_FRAGMENT}
 `;