import React from "react";
import Dashboard from "./views/Dashboard";
import { Routes, Route } from "react-router-dom";
import Login from "./views/Login";
import Category from "./views/Category";
import SubCategory from "./views/Subcategory";
import PrivateRoutes from "./utils/PrivateRoutes";
import PageNotFound from "./views/PageNotFound";
import ProductManage from "./views/ProductManage";

function App() {
  return (
    <div className="App">
      <Routes>
        <>
            <Route index path="/" element={<Login />} />
            <Route path="*" element={<PageNotFound />}/>
          <Route element={<PrivateRoutes />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/category" element={<Category />} />
            <Route path="/sub_category" element={<SubCategory />} />
            <Route path="/product_manage" element={<ProductManage />} />
          </Route>
        </>
      </Routes>
    </div>
  );
}

export default App;
