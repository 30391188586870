import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../fragments/metaFragments";

export const CREATESUBCATEGORY = gql`
    mutation createSubCategory(
        $categoryUuid: String!,
        $subCatName: String!,
        $subCatSlug: String!,
        $subCatStatus: SubCategoryStatusEnum!
    ){
        createSubCategory(
        input: {
            category_uuid: $categoryUuid
            sub_cat_name: $subCatName
            sub_cat_slug: $subCatSlug
            sub_cat_status: $subCatStatus
        }) {
            meta{
                ...MetaFragment
            }
        }
    }
    ${META_FRAGMENT}
`;
  