import React from "react";
import { ErrorMessage, useFormik } from 'formik';
import { LoginValidation } from "../utils/YupValidations";
import { useMutation } from "@apollo/client";
import { ADMINLOGIN } from "../framework/graphql/mutations/admin";
import { toast } from "react-toastify";
import { KEYS } from "../costant/constant";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loading/Loader";

const Login = () => {
  const initialValues = {
    email: localStorage.getItem('email') || '',
    password: localStorage.getItem('password') || ''
  }
  const [login, { loading }] = useMutation(ADMINLOGIN);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: LoginValidation,
    onSubmit:(values)=>{
      const formData = {};
      Object.assign(formData,{
        email: values.email,
        password: values.password
      });
      login({
        variables: formData
      }).then((res)=>{
        const data = res.data;
        toast.success(data?.adminLogin?.meta?.message);
        localStorage.setItem(KEYS.authAdminToken,data?.adminLogin?.data?.access_token);
        localStorage.setItem(KEYS.adminInfo, JSON.stringify(data?.adminLogin?.data?.user));
        navigate('/dashboard');
      },(err)=>{
        const data = err.data;
        toast.success(data?.adminLogin?.meta?.message);
      })
    }
  })
  if(loading){
    return (
        <Loader />
    )
  }
  return (
    <>
     <section className="background-radial-gradient overflow-hidden">
            <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
              <div className="row gx-lg-5 align-items-center mb-5">
                <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: "10" }}>
                  <h1
                    className="my-5 display-5 fw-bold ls-tight"
                    style={{ color: "hsl(218, 81%, 95%)" }}
                  >
                    AIRWAVE<br />
                    <span style={{ color: "hsl(218, 81%, 75%)" }}>
                     Industrial Solutions
                    </span>
                  </h1>
                  <p
                    className="mb-4 opacity-70"
                    style={{ color: "hsl(218, 81%, 85%)" }}
                  >
                    Welcome to Admin..!<br/>
                    Please enter you credentials...
                  </p>
                </div>

                <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                  <div
                    id="radius-shape-1"
                    className="position-absolute rounded-circle shadow-5-strong"
                  ></div>
                  <div
                    id="radius-shape-2"
                    className="position-absolute shadow-5-strong"
                  ></div>

                  <div className="card bg-glass">
                    <div className="card-body px-4 py-5 px-md-5 justify-content-center">
                    <div className="d-flex justify-content-center mb-2">
                    <a href="index.html" className="logo d-flex align-items-center w-auto">
                        <img src="assets/img/logo.png" alt=""/>
                    </a>
                </div>
                      <form onSubmit={formik.handleSubmit}>
                        {/* <!-- Email input --> */}
                        <div className="form-outline mb-4">
                         <input
                            type="email"
                            id="form3Example3"
                            name="email"
                            className="form-control"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="form-label" htmlFor="form3Example3">
                            Email address
                          </label>
                          {formik.errors.email &&
                          formik.touched.email && (
                            <div className="formik-error">
                              {formik.errors.email}
                            </div>
                          )}
                        </div> 

                        {/* <!-- Password input --> */}
                        <div className="form-outline mb-4">
                         <input
                            type="password"
                            id="form3Example4"
                            className="form-control"
                            name="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <label className="form-label" htmlFor="form3Example4">
                            Password
                          </label>
                          {formik.errors.password &&
                          formik.touched.password && (
                            <div className="formik-error">
                              {formik.errors.password}
                            </div>
                          )}
                        </div>

                        {/* <!-- Checkbox --> */}
                        <div className="form-check d-flex mb-4">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            value=""
                            id="form2Example33"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="form2Example33"
                          >
                            Remember me 
                          </label>
                        </div>
                        {/* <!-- Submit button --> */}
                        <div className="form-check d-flex justify-content-center mb-4">
                            <button
                            type="submit"
                            className="btn btn-primary btn-block mb-4"
                            style={{"backgroundColor":"#114B73"}}
                            >
                            Login
                            </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
      {/* <main> */}
     
        {/* <div className="container"> */}
          {/* <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                    <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                        <div className="d-flex justify-content-center py-4">
                            <a href="index.html" className="logo d-flex align-items-center w-auto">
                            <img src="assets/img/logo.png" alt=""/>
                            </a>
                        </div>
                       
                        <div className="card mb-3">

                            <div className="card-body">

                            <div className="pt-4 pb-2">
                                <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                <p className="text-center small">Enter your username & password to login</p>
                            </div>

                            <form className="row g-3 needs-validation">

                                <div className="col-12">
                                <label htmlFor="yourUsername" className="form-label">Username</label>
                                <div className="input-group has-validation">
                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                    <input type="text" name="username" className="form-control" id="yourUsername" />
                                    <div className="invalid-feedback">Please enter your username.</div>
                                </div>
                                </div>

                                <div className="col-12">
                                <label htmlFor="yourPassword" className="form-label">Password</label>
                                <input type="password" name="password" className="form-control" id="yourPassword" />
                                <div className="invalid-feedback">Please enter your password!</div>
                                </div>

                                <div className="col-12">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                    <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                </div>
                                </div>
                                <div className="col-12">
                                <button className="btn btn-primary w-100" type="submit">Login</button>
                                </div>
                            </form>

                            </div>
                        </div>

                        <div className="credits">
                        
                        </div>

                        </div>
                    </div>
                    </div>

                </section> */}

          {/* <!-- Section: Design Block --> */}
         
          {/* <!-- Section: Design Block --> */}
        {/* </div> */}
      {/* </main> */}
      {/* <!-- End #main --> */}

      {/* <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}
    </>
  );
};
export default Login;
