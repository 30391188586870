import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Aside from "../components/Aside/Aside";
import Footer from "../components/Footer";
import { toast } from "react-toastify";
import { ICategoryList } from "../types/category/category";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import { acceptedImageFormats } from "../costant/constant";
import { uploadFileClient } from "../framework/graphql/apolloclient";
import { CREATECATEGORY, DELETECATEGORY } from "../framework/graphql/mutations/Category";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ALLCATEGORYLIST } from "../framework/graphql/querys/Category";
import { CategoryValidationSchema } from "../utils/YupValidations";
import Loader from "../components/Loading/Loader";

const Category = () => {
  const [allCategory, setAllCategory] = useState<Array<ICategoryList>>([]);
  const [GetAllCategory, { loading, data }] = useLazyQuery(ALLCATEGORYLIST,{
    variables:{
      offset: 1,
      limit: 100,
      query: "",
      sortColumn: "id",
      sortOrder: "ASC"
    }
  });
  const [deleteCategory, { loading:deleteLoading }] = useMutation(DELETECATEGORY);
  const getData =()=>{
    GetAllCategory().then((res)=>{
      setAllCategory(res?.data?.listCategory?.data);
    });
  }
  useEffect(()=>{
   getData();
  },[data,allCategory]);

  const handleChange = (event: any) => {
    const { value } = event.target;
    const modifiedValue = value.replace(/\s/g, "-").toLowerCase();
    formik.setFieldValue("categoryname", value);
    formik.handleChange({
      target: {
        name: "slug",
        value: modifiedValue,
      },
    });
  };

  const initialValues = {
    categoryname: "",
    slug: "",
    file: null as File | null,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: CategoryValidationSchema,
    onSubmit: (values) => {
      if (values) {
        console.log("data", values);
        if(!values.file){
          formik.setErrors({file:"Category Image Required"});
          return
        }
        const variables ={
          image:values.file,
          categoryName: values.categoryname,
          categorySlug: values.slug,
          categoryStatus: 'ACTIVE'
        }
        uploadFileClient.mutate({
          mutation: CREATECATEGORY,
          variables: variables
        })
        .then((res: any) => {
          toast.success(res.data.createCategory.meta.message);
          GetAllCategory();
          formik.resetForm();
        })
        .catch((err: any) => {
          toast.error(err.message);
        })
        .finally(() => {
          console.log('this is the area when it\'s done processing, whether success or error.');
        });

      }
    },
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("file", acceptedFiles[0]);
    },
    accept: acceptedImageFormats,
  });
  const removeSelectedFile = () =>{
    formik.setFieldValue("file", null);
  }
  const handleDeleteCategory = async (categoryId:string) => {
    await deleteCategory({
      variables:{
        uuid: categoryId
      }
    }).then((res)=>{
      const data = res.data;
      toast.success(data?.deleteCategory?.meta?.message);
      getData();
    },(err)=>{
      const data = err.data;
      toast.success(data?.deleteCategory?.meta?.message);
    })
  };
  if(loading || deleteLoading){
    return (
        <Loader />
    )
  }
  return (
    <>
      <Header />
      <Aside />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Category</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Home</a>
              </li>
              <li className="breadcrumb-item active">Category</li>
            </ol>
          </nav>
          <div className="addButton">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="bi bi-plus"></i> Add New Category
            </button>
          </div>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Category List</h5>
                {/* <p>Add lightweight datatables to your project with using the <a href="https://github.com/fiduswriter/Simple-DataTables" target="_blank">Simple DataTables</a> library. Just add <code>.datatable</code> className name to any table you wish to conver to a datatable</p> */}

                {/* <!-- Table with stripped rows --> */}
                {allCategory && allCategory.length > 0 && (
                  <table className="table datatable">
                    <thead>
                      <tr>
                        <th scope="col">Sr No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Slug</th>
                        <th scope="col">Action</th>
                        {/* <th scope="col">Start Date</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {allCategory &&
                        allCategory.length &&
                        allCategory.map((row) => (
                          <tr key={row?.id}>
                            <td>{row?.id}</td>
                            <td>{row?.name}</td>
                            <td>{row?.slug}</td>
                            <td colSpan={3}>
                              <a href="#" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bi bi-pen"></i></a> | <a href="#" className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={()=>{handleDeleteCategory(row?.uuid)}}><i className="bi bi-trash-fill"></i></a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
                {/* <!-- End Table with stripped rows --> */}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Category
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form className="row g-2" onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <div className="col-md-12 mb-2">
                  <label> Category Name<i className="required">*</i></label>
                  <input
                    type="text"
                    name="categoryname"
                    className="form-control"
                    placeholder="Enter Category Name"
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.categoryname}
                  />
                  {formik.errors.categoryname &&
                    formik.touched.categoryname && (
                      <div className="formik-error">
                        {formik.errors.categoryname}
                      </div>
                    )}
                </div>
                <div className="col-md-12 mb-2">
                <label> Slug<i className="required">*</i></label>
                  <input
                    type="text"
                    name="slug"
                    className="form-control"
                    placeholder="Slug *"
                    value={formik.values.slug}
                    disabled
                  />
                  {formik.errors.slug && formik.touched.slug && (
                    <div className="formik-error">{formik.errors.slug}</div>
                  )}
                </div>
                <label> Select Category Image<i className="required">*</i></label>
                <div
                  {...getRootProps()}
                  className={`dropzone ${isDragActive ? "active" : ""} col-md-12 mb-2`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p>Drop the file here ...</p>
                  ) : (
                    <><p>Drag 'n' drop a file here, or click to select a file</p>
                    <p style={{fontSize:'12px'}}>image size recommended 477 * 477 px</p></>
                  )}
                </div>
                {formik.values.file && (
                  <div>
                    <span className="badge rounded-pill bg-info text-dark">{formik.values.file?.name}<button type="button" className="btn-close" aria-label="Close" onClick={removeSelectedFile}></button></span>
                  </div>
                )}
                {formik.errors.file && (
                  <div className="error">{formik.errors.file}</div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Category;
