import { gql } from "@apollo/client";
import { META_FRAGMENT } from "../fragments/metaFragments";

export const CREATEPRODUCT = gql`
mutation Fileupload(
    $mainImg:Upload!,
    $secImg:Upload,
    $thrdImg:Upload,
    $catUuid: String!,
    $subCatUuid: String!,
    $productName: String!,
    $partNumber: String,
    $outerTD: String ,
    $outerBD: String,
    $innerTD: String,
    $innerBD: String,
    $noCheckValve: String,
    $noPassValves: String,
    $thread: String,
    $height: String,
    $price: String!,
    $status: ProductStatusEnum!
    $topProduct:TopProductEditEnum!
    ){ 
    createProduct(
        input:{
            main_img:$mainImg,
            sec_img:$secImg,
            thrd_img:$thrdImg,
            cat_uuid: $catUuid ,
            sub_cat_uuid: $subCatUuid,
            name: $productName,
            part_number: $partNumber,
            outer_td: $outerTD ,
            outer_bd: $outerBD,
            inner_td: $innerTD,
            inner_bd:  $innerBD,
            no_check_valve: $noCheckValve,
            no_pass_valves: $noPassValves,
            thread: $thread,
            height: $height,
            price: $price,
            status: $status,
            top_product: $topProduct
  }){
        meta{
            ...MetaFragment
        }
    }
}
${META_FRAGMENT}
`;

export const DELETEPRODUCT = gql`
mutation deleteProduct($uuid: String!){
    deleteProduct(input: { uuid: $uuid }) {
        meta{
            ...MetaFragment
        }
    }
  }
${META_FRAGMENT}
`;