import { Navigate, Outlet } from "react-router-dom";
import {checkAuthentication} from "../helper/checkAuthentication";

const PrivateRoutes = () =>{
    let isAuthenticated:boolean = checkAuthentication();
    //  let isAuthenticated:boolean = true;
    return (
        isAuthenticated ? <Outlet/> : <Navigate to="/"/>
    )
}
export default PrivateRoutes;
