import * as Yup from 'yup';

export const LoginValidation = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character'
    ),
  });

export const  CategoryValidationSchema = Yup.object().shape({
  categoryname: Yup.string().required("Category name is required"),
  slug: Yup.string().required("Slug is requird"),
});

export const  SubCategoryValidationSchema = Yup.object().shape({
  categoryUuid: Yup.string().required("Please Select Category"),
  subcategoryname: Yup.string().required("Sub Category name is required"),
  slug: Yup.string().required("Slug is requird"),
});

export const ProductValidationSchema = Yup.object().shape({
  categoryUuid: Yup.string().required("Please Select Category"),
  subcategoryUuid: Yup.string().required("Please Select Sub Category"),
  productName: Yup.string().required("Product name is required"),
  partNumber: Yup.string().required("Part number is required"),
  price: Yup.string().required("Price is required"),
});