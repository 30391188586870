import { useFormik } from "formik";
import Aside from "../components/Aside/Aside";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ALLCATEGORYLIST } from "../framework/graphql/querys/Category";
import { ALLSUBCATEGORYLIST } from "../framework/graphql/querys/SubCategory";
import { ProductValidationSchema } from "../utils/YupValidations";
import { useDropzone } from "react-dropzone";
import { acceptedImageFormats } from "../costant/constant";
import { toast } from "react-toastify";
import { uploadFileClient } from "../framework/graphql/apolloclient";
import { CREATEPRODUCT, DELETEPRODUCT } from "../framework/graphql/mutations/Product";
import { ALLPRODUCTS } from "../framework/graphql/querys/Products";
import Loader from "../components/Loading/Loader";

const ProductManage =()=>{
  const[allSubCategory,setAllSubCategory] = useState<Array<any>>([]);
  const [allCategory,setAllCategory] = useState<Array<any>>([]);
  const [allProducts,setAllProducts] = useState<Array<any>>([]);
  const [deleteProduct, { loading:deleteLoading }] = useMutation(DELETEPRODUCT);
  const [GetAllCategory, { loading:categoryLoading, data:categoryData }] = useLazyQuery(ALLCATEGORYLIST,{
    variables:{
      offset: 1,
      limit: 100,
      query: "",
      sortColumn: "id",
      sortOrder: "ASC"
    }
  });
  const [GetAllSubCategory, { loading:subCategoryLoading, data:subCategoryData }] = useLazyQuery(ALLSUBCATEGORYLIST,{
    variables:{
      offset: 1,
      limit: 100,
      query: "",
      sortColumn: "id",
      sortOrder: "ASC"
    }
  });
  const [GetAllProducts, { loading:productsLoading, data:productsData }] = useLazyQuery(ALLPRODUCTS,{
    variables:{
      offset: 1,
      limit: 100,
      query: "",
      sortColumn: "id",
      sortOrder: "ASC"
    }
  });
  const initialValues = {
    mainImg: null as File | null,
    secImg:null as File | null,
    thrdImg:null as File | null,
    categoryUuid: "",
    subcategoryUuid: "",
    productName: "",
    partNumber: "",
    outerTD: "" ,
    outerBD: "",
    innerTD: "",
    innerBD: "",
    noCheckValve: "",
    noPassValves: "",
    thread: "",
    height: "",
    price: "",
    status: true,
    topProduct: false
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ProductValidationSchema,
    onSubmit: (values) => {
        if(values){
          const formData = {}
          if(!values.mainImg){
            formik.setErrors({mainImg:"Product Main Image Required"});
            return
          }
          const statusValue = values.status === true ? 'ACTIVE': 'INACTIVE';
          const topProductValue = values.topProduct === true ? 'YES': 'NO';
          Object.assign(formData,{
            mainImg: values.mainImg,
            catUuid: values.categoryUuid,
            subCatUuid: values.subcategoryUuid,
            productName: values.productName,
            partNumber: values.partNumber,
            outerTD: values.outerTD ,
            outerBD: values.outerBD,
            innerTD: values.innerTD,
            innerBD: values.innerBD,
            noCheckValve: values.noCheckValve,
            noPassValves: values.noPassValves,
            thread: values.thread,
            height: values.height,
            price: values.price,
            status: statusValue,
            topProduct: topProductValue
          });
          if(values.secImg){
            Object.assign(formData,{
              secImg: values.secImg,
            })
          }
          if(values.thrdImg){
            Object.assign(formData,{
              thrdImg: values.thrdImg,
            })
          }
          console.log('form data', formData);
          uploadFileClient.mutate({
            mutation: CREATEPRODUCT,
            variables: formData
          })
          .then((res: any) => {
            toast.success(res.data.createProduct.meta.message);
            GetAllProducts();
            formik.resetForm();
          })
          .catch((err: any) => {
            toast.error(err.message);
          })
          .finally(() => {
            console.log('this is the area when it\'s done processing, whether success or error.');
          });
        }
    },
  });
  const getAllProducts = useCallback(()=>{
    GetAllProducts().then((res)=>{
      setAllProducts(res?.data?.listProduct?.data);
    });
  },[GetAllProducts])
  useEffect(()=>{
    GetAllCategory().then((res)=>{
      setAllCategory(res?.data?.listCategory?.data);
    });
},[GetAllCategory, categoryData]);

useEffect(()=>{
    GetAllSubCategory().then((res)=>{
        setAllSubCategory(res?.data?.listSubCategory?.data);
    });
},[GetAllSubCategory, subCategoryData]);

useEffect(()=>{
  getAllProducts();
},[getAllProducts, productsData]);

const handleStatusChange =()=>{
  formik.setFieldValue("status", !formik.values.status);
}

const handleTopProductChange = ()=>{
  formik.setFieldValue("topProduct", !formik.values.topProduct);
}
  // Main Image upload
  const { getRootProps:mainImageRootProps, getInputProps:mainImageInputProps, isDragActive:isMainImageDrag } = useDropzone({
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("mainImg", acceptedFiles[0]);
    },
    accept: acceptedImageFormats,
  });
  const removeMainImageSelectedFile = () =>{
    formik.setFieldValue("mainImg", null);
  }

  // Second Image upload
  const { getRootProps:SecondImageRootProps, getInputProps:SecondImageInputProps, isDragActive:isSecondImageDrag } = useDropzone({
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("secImg", acceptedFiles[0]);
    },
    accept: acceptedImageFormats,
  });
  const removeSecondImageSelectedFile = () =>{
    formik.setFieldValue("secImg", null);
  }
  // Third Image upload
  const { getRootProps:ThirdImageRootProps, getInputProps:ThirdImageInputProps, isDragActive:isThirdImageDrag } = useDropzone({
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("thrdImg", acceptedFiles[0]);
    },
    accept: acceptedImageFormats,
  });
  const removeThirdImageSelectedFile = () =>{
    formik.setFieldValue("thrdImg", null);
  }
  const handleDeleteProduct = async (productId:string) => {
    await deleteProduct({
      variables:{
        uuid: productId
      }
    }).then((res)=>{
      const data = res.data;
      toast.success(data?.deleteProduct?.meta?.message);
      getAllProducts();
    },(err)=>{
      const data = err.data;
      toast.success(data?.deleteProduct?.meta?.message);
    })
  };
  if(categoryLoading || subCategoryLoading || productsLoading || deleteLoading){
    return (
        <Loader />
    )
  }
  return(
        <>
      <Header />
      <Aside />
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Manage Products</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/dashboard">Home</a>
              </li>
              <li className="breadcrumb-item active">Products</li>
            </ol>
          </nav> 
          <div className="addButton">
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="bi bi-plus"></i> Add New Products
            </button>
          </div>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Products List</h5>
                {allProducts && allProducts.length > 0 && (
                  <table className="table datatable">
                    <thead>
                      <tr>
                      <th scope="col">Part No.</th>
                        <th scope="col">Name</th>
                        <th scope="col">Sub Category</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allProducts &&
                        allProducts.length &&
                        allProducts.map((row) => (
                          <tr key={row?.uuid}>
                            <td>{row?.part_number}</td>
                            <td>{row?.name}</td>
                             <td>{row?.sub_category_name}</td>
                            <td colSpan={3}>
                              <a href="#" className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"><i className="bi bi-pen"></i></a> | <a href="#" className="link-danger link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover" onClick={()=>{handleDeleteProduct(row?.uuid)}}><i className="bi bi-trash-fill"></i></a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Products
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form className="row g-2" onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label>Category Name<i className="required">*</i></label>
                      <select className="form-select" 
                          name="categoryUuid"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.categoryUuid}
                      >
                          <option value="">Select an option</option>
                          {allCategory &&
                          allCategory.length &&
                          allCategory.map((row) => 
                              <option key={row?.uuid} value={row?.uuid}>{row?.name}</option>
                          )}
                      </select>
                      {formik.errors.categoryUuid &&
                      formik.touched.categoryUuid && (
                        <div className="formik-error">
                          {formik.errors.categoryUuid}
                        </div>
                      )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>Sub Category Name<i className="required">*</i></label>
                      <select className="form-select" 
                          name="subcategoryUuid"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.subcategoryUuid}
                      >
                          <option value="">Select an option</option>
                          {allSubCategory &&
                          allSubCategory.length &&
                          allSubCategory.map((row) => 
                              <option key={row?.uuid} value={row?.uuid}>{row?.name}</option>
                          )}
                      </select>
                      {formik.errors.subcategoryUuid &&
                      formik.touched.subcategoryUuid && (
                        <div className="formik-error">
                          {formik.errors.subcategoryUuid}
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <label>Product Name<i className="required">*</i></label>
                  <input
                    type="text"
                    name="productName"
                    className="form-control"
                    placeholder="Enter Product Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.productName}
                  />
                  {formik.errors.productName &&
                    formik.touched.productName && (
                      <div className="formik-error">
                        {formik.errors.productName}
                      </div>
                    )}
                </div>
                <div className="col-md-12 mb-2">
                  <label>Part Number<i className="required">*</i></label>
                  <input
                    type="text"
                    name="partNumber"
                    className="form-control"
                    placeholder="Enter Part Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.partNumber}
                  />
                  {formik.errors.partNumber &&
                    formik.touched.partNumber && (
                      <div className="formik-error">
                        {formik.errors.partNumber}
                      </div>
                    )}
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label>Top Outer Dimension</label>
                    <input
                      type="text"
                      name="outerTD"
                      className="form-control"
                      placeholder="Enter Top Outer Dimension"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.outerTD}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>Bottom Outer Dimension</label>
                    <input
                      type="text"
                      name="outerBD"
                      className="form-control"
                      placeholder="Enter Bottom Outer Dimension"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.outerBD}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label>Top Inner Dimension</label>
                    <input
                      type="text"
                      name="innerTD"
                      className="form-control"
                      placeholder="Enter Top Inner Dimension"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.innerTD}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>Bottom Inner Dimension</label>
                    <input
                      type="text"
                      name="innerBD"
                      className="form-control"
                      placeholder="Enter Bottom Inner Dimension"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.innerBD}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label>Number of Check Valve</label>
                    <input
                      type="text"
                      name="noCheckValve"
                      className="form-control"
                      placeholder="Enter Number of Check Valve"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.noCheckValve}
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <label>Number of Pass Valves</label>
                    <input
                      type="text"
                      name="noPassValves"
                      className="form-control"
                      placeholder="Enter Number of Pass Valves"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.noPassValves}
                    />
                  </div>
                </div>
                <div className="row">
                <div className="col-md-6 mb-2">
                  <label>Thread</label>
                  <input
                    type="text"
                    name="thread"
                    className="form-control"
                    placeholder="Enter Thread"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.thread}
                  />
                </div>
                <div className="col-md-6 mb-2">
                  <label>Height</label>
                  <input
                    type="text"
                    name="height"
                    className="form-control"
                    placeholder="Enter Height"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.height}
                  />
                </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <label>Price<i className="required">*</i></label>
                    <input
                      type="text"
                      name="price"
                      className="form-control"
                      placeholder="Enter Price"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.price}
                    />
                    {formik.errors.price &&
                      formik.touched.price && (
                        <div className="formik-error">
                          {formik.errors.price}
                        </div>
                      )}
                  </div>
                  <div className="col-md-3 mb-2">
                      <label>Status</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" name="status" onChange={handleStatusChange} checked={formik.values.status}/>
                      </div>
                  </div>
                  <div className="col-md-3 mb-2">
                      <label>Top Product</label>
                      <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" name="status" onChange={handleTopProductChange} checked={formik.values.topProduct}/>
                      </div>
                  </div>
                </div>
                {/* Main Image Upload */}
                <div className="col-md-12 mb-2">
                <label> Select Main Image<i className="required">*</i></label>
                <div
                  {...mainImageRootProps()}
                  className={`dropzone ${isMainImageDrag ? "active" : ""} col-md-12 mb-2`}
                >
                  <input {...mainImageInputProps()} />
                  {isMainImageDrag ? (
                    <p>Drop the file here ...</p>
                  ) : (
                    <><p>Drag 'n' drop a file here, or click to select a file</p>
                    <p style={{fontSize:'12px'}}>image size recommended 477 * 477 px</p></>
                  )}
                </div>
                {formik.values.mainImg && (
                  <div>
                    <span className="badge rounded-pill bg-info text-dark">{formik.values.mainImg?.name}<button type="button" className="btn-close" aria-label="Close" onClick={removeMainImageSelectedFile}></button></span>
                  </div>
                )}
                {formik.errors.mainImg && (
                  <div className="error">{formik.errors.mainImg}</div>
                )}
                </div>
                {/* Second Image Upload */}
                <div className="col-md-12 mb-2">
                <label> Select Second Image</label>
                <div
                  {...SecondImageRootProps()}
                  className={`dropzone ${isSecondImageDrag ? "active" : ""} col-md-12 mb-2`}
                >
                  <input {...SecondImageInputProps()} />
                  {isSecondImageDrag ? (
                    <p>Drop the file here ...</p>
                  ) : (
                    <><p>Drag 'n' drop a file here, or click to select a file</p>
                    <p style={{fontSize:'12px'}}>image size recommended 477 * 477 px</p></>
                  )}
                </div>
                {formik.values.secImg && (
                  <div>
                    <span className="badge rounded-pill bg-info text-dark">{formik.values.secImg?.name}<button type="button" className="btn-close" aria-label="Close" onClick={removeSecondImageSelectedFile}></button></span>
                  </div>
                )}
                {formik.errors.secImg && (
                  <div className="error">{formik.errors.secImg}</div>
                )}
                </div>
                {/* Third Image Upload */}
                <div className="col-md-12 mb-2">
                <label> Select Third Image</label>
                <div
                  {...ThirdImageRootProps()}
                  className={`dropzone ${isThirdImageDrag ? "active" : ""} col-md-12 mb-2`}
                >
                  <input {...ThirdImageInputProps()} />
                  {isThirdImageDrag ? (
                    <p>Drop the file here ...</p>
                  ) : (
                    <><p>Drag 'n' drop a file here, or click to select a file</p>
                    <p style={{fontSize:'12px'}}>image size recommended 477 * 477 px</p></>
                  )}
                </div>
                {formik.values.thrdImg && (
                  <div>
                    <span className="badge rounded-pill bg-info text-dark">{formik.values.thrdImg?.name}<button type="button" className="btn-close" aria-label="Close" onClick={removeThirdImageSelectedFile}></button></span>
                  </div>
                )}
                {formik.errors.thrdImg && (
                  <div className="error">{formik.errors.thrdImg}</div>
                )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    formik.resetForm();
                  }}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
    );
}
export default ProductManage;