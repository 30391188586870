export const KEYS = {
  userInfo: 'userInfo',
  adminInfo: 'adminInfo',
  authAdminToken: 'authAdminToken'
}
export enum CategoryStatus {
  ACTIVE, 
  INACTIVE,
  ARCHIVED,
}
export const acceptedImageFormats  = {
    "image/*": [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".bmp",
      ".webp",
      ".svg",
      ".tiff",
      ".ico",
    ],
  };